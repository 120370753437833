<template>
  <div>
    <SimpleTable :tableProps="tableProps" :queryFun="queryFun">
      <div slot="action" slot-scope="{ data }">
        <el-button @click="toDetail(true, data.row)" type="text"
          >编辑</el-button
        >
        <el-button @click="toDetail(false, data.row)" type="text"
          >查看详情</el-button
        >
      </div>
    </SimpleTable>
  </div>
</template>

<script>
import SimpleTable from "./components/SimpleTable";
import { getEnterpriseList } from "@/api/safeDuty.js";
export default {
  components: { SimpleTable },
  data() {
    return {
      tableProps: {
        currentPageKey: "current",
        currentSizeKey: "size",
        totalKey: "total",
        height: 600,
        searchConfigs: [
          {
            label: "企业名称",
            type: "input",
            modelKey: "enterpriseName",
          },
          {
            label: "地区",
            type: "distpicker",
            regionKeys: ["province", "city"],
            modelTextKey: "areaDist",
            modelKey: "areaDist",
          },
          {
            label: "进驻时间",
            type: "daterange",
            daterangeKeys: ["residenceTimeStart", "residenceTimeEnd"],
            modelKey: "residenceTime",
          },
        ],
        columnProps: [
          {
            prop: "1",
            label: "序号",
            width: "55",
            type: "index",
            isShow: true,
          },
          {
            prop: "insuredName",
            label: "企业名称",
            width: "",
            type: "",
            isShow: true,
          },

          {
            prop: "areaProvince",
            label: "省份",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "areaCity",
            label: "城市",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "safetyInsurancePoliciesNumber",
            label: "安责险保单数",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "securityTotalPremium",
            label: "安责险总保费",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "trainingNumber",
            label: "培训次数",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "claimsNumber",
            label: "理赔次数",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "claimTotalAmount",
            label: "理赔金额",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "createTime",
            label: "进驻时间",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "action",
            label: "操作",
            width: "",
            type: "",
            isShow: true,
            slot: true,
          },
        ],
      },
    };
  },
  methods: {
    async queryFun(queryParams) {
      let res = await getEnterpriseList(queryParams);
      return {
        localData: res.data.records || [{}],
        total: res.data.total,
      };
    },
    toDetail(edit = false, item = {}) {
      this.$router.push({
        name: "safeDutyEnterpriseManagementDetail",
        params: {
          id: item?.id,
          isEdit: edit,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
